<template>
  <v-main>
    <router-view />
  </v-main>
</template>
<script>
export default {
  name: 'AuthenticationView'
}
</script>
<style lang="">

</style>
